import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  MscSubscriptionDetailsGQL,
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "msc-subscription",
  templateUrl: "msc-subscription.html",
})
export class MscSubscriptionComponent extends GenericSubscriptionComponent<
  MscSubscriptionDetailsGQL,
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetailsType
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = true; // customer can view the linked port subscription?
  public portSubscriptionName = "Port subscription name";

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: MscSubscriptionDetailsGQL,
  ) {
    super(api, auth, dialog, route);
  }

  protected onQuerySuccess(result: ApolloQueryResult<MscSubscriptionDetailsQuery>): MscSubscriptionDetailsType {
    return result.data.mscDetails as MscSubscriptionDetailsType;
  }
}
