import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DialogNotificationSettingsComponent } from "../../dialogs/dialog-notification-settings/dialog-notification-settings";
import { MatDialog } from "@angular/material/dialog";
import { NotificationButtonModel } from "./notification-button.model";
import { MinimalImpactNotificationPipe } from "../../../helpers/MinimalImpactNotificationPipe/MinimalImpactNotificationPipe";

@Component({
  selector: "notification-button-replicated",
  templateUrl: "notification-button.html",
  styleUrls: ["notification-button.scss"],
})
export class NotificationButtonReplicated implements OnChanges {
  @Input() subscription: NotificationButtonModel;
  public isActive = true;
  public minImpact = "Loss of Resiliency";
  public impactDescription = "";
  private impactDescriptions = {};

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
  ) {
    // TODO #190 the page sometimes loads faster than the translations
    let receiveNotificationsFor = this.translate.instant("Notification.ReceiveNotificationsFor");
    this.impactDescriptions = {
      Downtime: `${receiveNotificationsFor} ${this.translate.instant("Notification.Status.Downtime")}`,
      "Loss of Resiliency": `${receiveNotificationsFor} ${this.translate.instant(
        "Notification.Status.LossofResiliency",
      )}`,
      "Reduced Redundancy": `${receiveNotificationsFor} ${this.translate.instant(
        "Notification.Status.ReducedRedundancy",
      )}`,
      Never: `${receiveNotificationsFor} ${this.translate.instant("Notification.Status.Never")}`,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.minImpact = new MinimalImpactNotificationPipe().transform(this.subscription, "Loss of Resiliency");
    this.updateMinimalImpact();
  }

  updateMinimalImpact() {
    this.impactDescription = this.impactDescriptions[this.minImpact];
    this.isActive = this.minImpact !== "Never";
  }

  openNotificationSettingsDialog() {
    const dialogRef = this.dialog.open(DialogNotificationSettingsComponent, {
      data: {
        subscriptionId: this.subscription.subscriptionId,
        name: this.subscription.description,
        impactSetting: this.minImpact,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.minImpact = result.minimalImpactNotification;
      this.updateMinimalImpact();
    });
  }
}
