import { gql } from "apollo-angular";

export const CERT_MITIGATION_PREFIXES_QUERY = gql`
  query CertMitigationPrefixes($customer: UUID!) {
    ipPrefixDdos(searchFilter: { accessCustomerId: $customer }) {
      page {
        customerId
        description
        autoMitigation
        prefix
        status
        version
      }
    }
  }
`;
